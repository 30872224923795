











































import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterServices } from "@/services/master.service";
import Vue, { PropType } from "vue";
import { OPTION_BASIC_FEATURE } from "./constant/optioninventory.constant";
import { COMPONENT_NAME } from "./Preferences.vue";
export interface IPrefInventory {
  inventory_costing_method: string;
  basic: string[];
}
export default Vue.extend({
  name: "PreferencesInventory",
  props: {
    firstLoad: Boolean,
    dataFirstLoad: {} as PropType<IPrefInventory>,
  },
  watch: {
    dataFirstLoad: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstLoad && newValue) {
          this.valPref.basic = newValue.basic;
          this.valPref.inventory_costing_method =
            newValue.inventory_costing_method;
          this.$emit("onChangeLoad", {
            name: COMPONENT_NAME.PREF_INVENTORY,
            val: true,
          });
        }
      },
    },
  },
  data() {
    return {
      optCostingMethod: [] as ResponseListMaster[],
      optBasicFeature: OPTION_BASIC_FEATURE,
      invCheckedList: [],
      valPref: {
        inventory_costing_method: "",
        basic: [] as string[],
      } as IPrefInventory,
    };
  },
  methods: {
    onChangeCosting(value): void {
      this.valPref.inventory_costing_method = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_INVENTORY,
        val: this.valPref,
      });
    },
    onChecked(checkedValues): void {
      this.valPref.basic = checkedValues;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_INVENTORY,
        val: this.valPref,
      });
    },
  },
  created() {
    const paramsCostingMethods: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "COSTING_METHOD",
    };
    masterServices
      .listMaster(paramsCostingMethods)
      .then(responseCostinMethod => {
        this.optCostingMethod = responseCostinMethod;
      });
  },
});
