
























































































































import Vue from "vue";
import PreferencesFeature, { IPrefFeature } from "./PreferencesFeature.vue";
import PreferencesSales, { IPrefSales } from "./PreferencesSales.vue";
import PreferencesInventory, {
  IPrefInventory,
} from "./PreferencesInventory.vue";
import PreferencesAccountSetup, {
  IPrefAccountSetup,
} from "./PreferencesAccountSetup.vue";
import PreferencesPurchases, {
  IPrefPurchases,
} from "./PreferencesPurchases.vue";
import { settingsServices } from "@/services/settings.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  RequestUpdatePreference,
  ResponsePreference,
} from "@/models/interface/settings.interface";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import MNotificationVue from "@/mixins/MNotification.vue";
import { mapActions, mapState } from "vuex";

export enum COMPONENT_NAME {
  PREF_FEATURE = "PREFRENCES_FEATURE",
  PREF_SALES = "PREFERENCES_SALES",
  PREF_PURCHASES = "PREFERENCES_PURCHASES",
  PREF_INVENTORY = "PREFERENCES_INVENTORY",
  PREF_ACCOUNT_SETUP = "PREFERENCES_ACCOUNT_SETUP",
}

export default Vue.extend({
  name: "Preferences",
  mixins: [MNotificationVue],
  components: {
    "Preferences-Feature": PreferencesFeature,
    "Preferences-Sales": PreferencesSales,
    "Preferences-Inventory": PreferencesInventory,
    "Preferences-Account-Setup": PreferencesAccountSetup,
    "Preferences-Purchases": PreferencesPurchases,
  },
  data() {
    return {
      firstLoadComponentTab: {
        feature: false as boolean,
        sales: false as boolean,
        purchase: false as boolean,
        inventory: false as boolean,
        accountSetup: false as boolean,
      },
      listIdCoa: [] as string[],
      dataFeatureDetail: [] as ResponsePreference[],
      dataSalesDetail: [] as ResponsePreference[],
      dataPurchaseDetail: [] as ResponsePreference[],
      dataInventoryDetail: [] as ResponsePreference[],
      dataAccountSetupDetail: [] as ResponsePreference[],
      activeTabPane: "Feature" as string,
      columnTabPane: [
        "Feature",
        "Sales",
        "Purchases",
        "Inventory",
        "Account Setup",
      ] as string[],
      loadingSave: false as boolean,
      prefFeature: {
        basic: [] as string[],
        additional: [] as string[],
        currency: {
          feature_base_currency: "" as string,
          feature_decimal_format: "" as string,
          feature_decimal_point: "" as string,
          feature_discount_before_after_tax: "" as string,
        },
        taxRate: {
          feature_sales_tax_rate: "" as string,
          feature_purchase_tax_rate: "" as string,
        },
      } as IPrefFeature,
      prefSales: {
        sales_top: "" as string,
        basic: [] as string[],
        sales_commision_counting_from: "" as string,
      } as IPrefSales,
      prefPurchase: {
        purchase_top: "" as string,
        basic: [] as string[],
      } as IPrefPurchases,
      prefInventory: {
        inventory_costing_method: "" as string,
        basic: [] as string[],
      } as IPrefInventory,
      prefAccountSetup: {
        balanceSheet: {
          account_setup_opening_balance_equity: "" as string,
          account_setup_retained_earning: "" as string,
          account_setup_exchange_rate_variance_gain: "" as string,
          account_setup_exchange_rate_variance_loss: "" as string,
          account_setup_current_year_profit: "" as string,
          account_setup_current_month_profit: "" as string,
        },
        salesPurchase: {
          account_setup_sales_revenue: "" as string,
          account_setup_sales_discount: "" as string,
          account_setup_sales_return: "" as string,
          account_setup_sales_delivery: "" as string,
          account_setup_unearned_revenue: "" as string,
          account_setup_unbilled_sales: "" as string,
          account_setup_unbilled_receivables: "" as string,
          account_setup_sales_bad_debt: "" as string,
          account_setup_purchase_bad_debt: "" as string,
          account_setup_purchase_cogs: "" as string,
          account_setup_purchase_shipping: "" as string,
          account_setup_purchase_return: "" as string,
          account_setup_unbilled_payables: "" as string,
          account_setup_rounding_account_gain: "" as string,
          account_setup_rounding_account_loss: "" as string,
          account_setup_purchase_price_variance: "" as string,
          account_setup_purchase_discount: "" as string,
          account_setup_prepayment_dealer: "" as string,
        },
        arap: {
          account_setup_account_receiviables: "" as string,
          account_setup_account_payables: "" as string,
          account_setup_invoice_price_variance: "" as string,
          account_setup_prepayment_ap: "" as string,
          account_setup_prepayment_ar: "" as string,
        },
        inventory: {
          account_setup_inventory: "" as string,
          account_setup_inventory_non_stock: "" as string,
          account_setup_inventory_waste: "" as string,
          account_setup_stock_adjustment: "" as string,
          account_setup_stock_variance: "" as string,
        },
        tax: {
          account_setup_vat_in: "" as string,
          account_setup_vat_out: "" as string,
        },
      } as IPrefAccountSetup,
    };
  },
  computed: {
    ...mapState({
      preferenceField: (st: any) => st.preferenceStore.field,
    }),
  },
  methods: {
    ...mapActions({
      dispatchAssigneeField: "preferenceStore/dispatchAssigneeField",
    }),
    onChangeLoad(param: { name: string; val: boolean }) {
      switch (param.name) {
        case COMPONENT_NAME.PREF_FEATURE:
          this.firstLoadComponentTab.feature = param.val;
          break;
        case COMPONENT_NAME.PREF_SALES:
          this.firstLoadComponentTab.sales = param.val;
          break;
        case COMPONENT_NAME.PREF_PURCHASES:
          this.firstLoadComponentTab.purchase = param.val;
          break;
        case COMPONENT_NAME.PREF_INVENTORY:
          this.firstLoadComponentTab.inventory = param.val;
          break;
        case COMPONENT_NAME.PREF_ACCOUNT_SETUP:
          this.firstLoadComponentTab.accountSetup = param.val;
          break;
        default:
          break;
      }
    },
    handleNext() {
      const index = this.columnTabPane.indexOf(this.activeTabPane);
      this.activeTabPane = this.columnTabPane[index + 1];
    },
    handleBack() {
      const index = this.columnTabPane.indexOf(this.activeTabPane);
      this.activeTabPane = this.columnTabPane[index - 1];
    },
    handleChangeMenu(value) {
      this.activeTabPane = value;
    },
    onFeatureChange(param: { name: string; val: any }): void {
      switch (param.name) {
        case COMPONENT_NAME.PREF_FEATURE:
          this.prefFeature = param.val;
          break;
        case COMPONENT_NAME.PREF_SALES:
          this.prefSales = param.val;
          break;
        case COMPONENT_NAME.PREF_PURCHASES:
          this.prefPurchase = param.val;
          break;
        case COMPONENT_NAME.PREF_INVENTORY:
          this.prefInventory = param.val;
          break;
        case COMPONENT_NAME.PREF_ACCOUNT_SETUP:
          this.prefAccountSetup = param.val;
          break;
        default:
          break;
      }
    },
    handleSetFeature(dataFeature) {
      dataFeature.forEach(dataForEach => {
        if (dataForEach.value === "true") {
          this.prefFeature.basic.push(dataForEach.key);
        }
        if (dataForEach.key === "feature_decimal_format") {
          this.prefFeature.currency.feature_decimal_format = dataForEach.value;
        } else if (dataForEach.key === "feature_decimal_point") {
          this.prefFeature.currency.feature_decimal_point = dataForEach.value;
        } else if (dataForEach.key === "feature_discount_before_after_tax") {
          this.prefFeature.currency.feature_discount_before_after_tax =
            dataForEach.value;
        } else if (dataForEach.key === "feature_base_currency") {
          this.prefFeature.currency.feature_base_currency = dataForEach.value;
        } else if (dataForEach.key === "feature_sales_tax_rate") {
          this.prefFeature.taxRate.feature_sales_tax_rate = dataForEach.value;
        } else if (dataForEach.key === "feature_purchase_tax_rate") {
          this.prefFeature.taxRate.feature_purchase_tax_rate =
            dataForEach.value;
        } else if (dataForEach.key === "feature_default_assignee") {
          this.dispatchAssigneeField(dataForEach.value); // dataForEach.value = secure id contact
        }
      });
    },
    handleSetSales(dataSales) {
      dataSales.forEach(dataForEach => {
        if (dataForEach.value === "true") {
          this.prefSales.basic.push(dataForEach.key);
        }
        if (dataForEach.key === "sales_top") {
          this.prefSales.sales_top = dataForEach.value;
        } else if (dataForEach.key === "sales_commision_counting_from") {
          this.prefSales.sales_commision_counting_from = dataForEach.value;
        }
      });
    },
    handleSetPurchase(dataPurchase) {
      dataPurchase.forEach(dataForEach => {
        if (dataForEach.value === "true") {
          this.prefPurchase.basic.push(dataForEach.key);
        }
        if (dataForEach.key === "purchase_top") {
          this.prefPurchase.purchase_top = dataForEach.value;
        }
      });
    },
    handleSetInventory(dataInventory) {
      dataInventory.forEach(dataForEach => {
        if (dataForEach.value === "true") {
          this.prefInventory.basic.push(dataForEach.key);
        }
        if (dataForEach.key === "inventory_costing_method") {
          this.prefInventory.inventory_costing_method = dataForEach.value;
        }
      });
    },
    handleSetAccountSetup(dataAccountSetup) {
      dataAccountSetup.forEach(dataForEach => {
        for (const key in this.prefAccountSetup) {
          if (
            typeof this.prefAccountSetup[key] === "object" &&
            !Array.isArray(this.prefAccountSetup[key]) &&
            this.prefAccountSetup[key] !== null
          ) {
            const element = this.prefAccountSetup[key];
            for (const keyObject in element) {
              if (keyObject === dataForEach.key) {
                this.prefAccountSetup[key][keyObject] = dataForEach.value;
                this.listIdCoa = [...this.listIdCoa, dataForEach.value];
              }
            }
          }
        }
      });
    },
    handleSubmitFeature(): RequestUpdatePreference[] {
      const temp: RequestUpdatePreference[] = [];
      this.dataFeatureDetail = this.dataFeatureDetail.map(dataMap => {
        if (dataMap.value === "true" || dataMap.value === "false") {
          if (this.prefFeature.basic.includes(dataMap.key)) {
            temp.push({
              key: dataMap.key,
              value: "true",
              secureId: dataMap.id,
            });
            return { ...dataMap, value: "true" };
          } else {
            temp.push({
              key: dataMap.key,
              value: "false",
              secureId: dataMap.id,
            });
            return { ...dataMap, value: "false" };
          }
        } else if (dataMap.key === "feature_decimal_format") {
          temp.push({
            key: dataMap.key,
            value: this.prefFeature.currency.feature_decimal_format,
            secureId: dataMap.id,
          });
          return {
            ...dataMap,
            value: this.prefFeature.currency.feature_decimal_format,
          };
        } else if (dataMap.key === "feature_discount_before_after_tax") {
          temp.push({
            key: dataMap.key,
            value: this.prefFeature.currency.feature_discount_before_after_tax,
            secureId: dataMap.id,
          });
          return {
            ...dataMap,
            value: this.prefFeature.currency.feature_discount_before_after_tax,
          };
        } else if (dataMap.key === "feature_base_currency") {
          temp.push({
            key: dataMap.key,
            value: this.prefFeature.currency.feature_base_currency,
            secureId: dataMap.id,
          });
          return {
            ...dataMap,
            value: this.prefFeature.currency.feature_base_currency,
          };
        } else if (dataMap.key === "feature_sales_tax_rate") {
          temp.push({
            key: dataMap.key,
            value: this.prefFeature.taxRate.feature_sales_tax_rate,
            secureId: dataMap.id,
          });
          return {
            ...dataMap,
            value: this.prefFeature.taxRate.feature_sales_tax_rate,
          };
        } else if (dataMap.key === "feature_purchase_tax_rate") {
          temp.push({
            key: dataMap.key,
            value: this.prefFeature.taxRate.feature_purchase_tax_rate,
            secureId: dataMap.id,
          });
          return {
            ...dataMap,
            value: this.prefFeature.taxRate.feature_purchase_tax_rate,
          };
        } else if (dataMap.key === "feature_default_assignee") {
          temp.push({
            key: dataMap.key,
            value: this.preferenceField.invoiceArAssignee.value,
            secureId: dataMap.id,
          });
          return {
            ...dataMap,
            value: this.preferenceField.invoiceArAssignee.value,
          };
        } else {
          // this key surely decimal option
          temp.push({
            key: dataMap.key,
            value: this.prefFeature.currency.feature_decimal_point,
            secureId: dataMap.id,
          });
          return {
            ...dataMap,
            value: this.prefFeature.currency.feature_decimal_point,
          };
        }
      });

      return temp;
    },
    handleSubmitSales(): RequestUpdatePreference[] {
      const temp: RequestUpdatePreference[] = [];
      this.dataSalesDetail = this.dataSalesDetail.map(dataMap => {
        if (dataMap.value === "true" || dataMap.value === "false") {
          if (this.prefSales.basic.includes(dataMap.key)) {
            temp.push({
              key: dataMap.key,
              value: "true",
              secureId: dataMap.id,
            });
            return { ...dataMap, value: "true" };
          } else {
            temp.push({
              key: dataMap.key,
              value: "false",
              secureId: dataMap.id,
            });
            return { ...dataMap, value: "false" };
          }
        } else if (dataMap.key === "sales_top") {
          temp.push({
            key: dataMap.key,
            value: this.prefSales.sales_top,
            secureId: dataMap.id,
          });
          return { ...dataMap, value: this.prefSales.sales_top };
        } else {
          // this key surely sales_commision_counting_from
          temp.push({
            key: dataMap.key,
            value: this.prefSales.sales_commision_counting_from,
            secureId: dataMap.id,
          });
          return {
            ...dataMap,
            value: this.prefSales.sales_commision_counting_from,
          };
        }
      });

      return temp;
    },
    handleSubmitPurchase(): RequestUpdatePreference[] {
      const temp: RequestUpdatePreference[] = [];
      this.dataPurchaseDetail = this.dataPurchaseDetail.map(dataMap => {
        if (dataMap.value === "true" || dataMap.value === "false") {
          if (this.prefPurchase.basic.includes(dataMap.key)) {
            temp.push({
              key: dataMap.key,
              value: "true",
              secureId: dataMap.id,
            });
            return { ...dataMap, value: "true" };
          } else {
            temp.push({
              key: dataMap.key,
              value: "false",
              secureId: dataMap.id,
            });
            return { ...dataMap, value: "false" };
          }
        } else {
          // this key surely purchase_top
          temp.push({
            key: dataMap.key,
            value: this.prefPurchase.purchase_top,
            secureId: dataMap.id,
          });
          return { ...dataMap, value: this.prefPurchase.purchase_top };
        }
      });

      return temp;
    },
    handleSubmitInventory(): RequestUpdatePreference[] {
      const temp: RequestUpdatePreference[] = [];
      this.dataInventoryDetail = this.dataInventoryDetail.map(dataMap => {
        if (dataMap.value === "true" || dataMap.value === "false") {
          if (this.prefInventory.basic.includes(dataMap.key)) {
            temp.push({
              key: dataMap.key,
              value: "true",
              secureId: dataMap.id,
            });
            return { ...dataMap, value: "true" };
          } else {
            temp.push({
              key: dataMap.key,
              value: "false",
              secureId: dataMap.id,
            });
            return { ...dataMap, value: "false" };
          }
        } else {
          // this key surely inventory_costing_method
          temp.push({
            key: dataMap.key,
            value: this.prefInventory.inventory_costing_method,
            secureId: dataMap.id,
          });
          return {
            ...dataMap,
            value: this.prefInventory.inventory_costing_method,
          };
        }
      });

      return temp;
    },
    handleSubmitAccountSetup(): RequestUpdatePreference[] {
      const temp: RequestUpdatePreference[] = [];
      this.dataAccountSetupDetail = this.dataAccountSetupDetail.map(dataMap => {
        const data: ResponsePreference = {
          createdDate: "",
          id: "",
          key: "",
          modifiedDate: "",
          value: "",
        };
        for (const key in this.prefAccountSetup) {
          if (
            typeof this.prefAccountSetup[key] === "object" &&
            !Array.isArray(this.prefAccountSetup[key]) &&
            this.prefAccountSetup[key] !== null
          ) {
            const element = this.prefAccountSetup[key];
            for (const keyObject in element) {
              if (keyObject === dataMap.key) {
                data.id = dataMap.id;
                data.createdDate = dataMap.createdDate;
                data.key = dataMap.key;
                data.modifiedDate = dataMap.modifiedDate;
                data.value = element[keyObject];
                temp.push({
                  value: element[keyObject],
                  key: dataMap.key,
                  secureId: dataMap.id,
                });
              }
            }
          }
        }
        return data;
      });

      return temp;
    },
    onSubmit(): void {
      let payload = [
        ...this.handleSubmitFeature(),
        ...this.handleSubmitSales(),
        ...this.handleSubmitPurchase(),
        ...this.handleSubmitInventory(),
        ...this.handleSubmitAccountSetup(),
      ] as RequestUpdatePreference[];

      this.loadingSave = true;
      settingsServices
        .updatePreference(payload)
        .then(() => {
          this.showNotifSuccess("notif_update_success");
          const paramsFeature: RequestQueryParamsModel = {};
          settingsServices.getPreference(paramsFeature).then(response => {
            this.$store.commit(
              `preferenceStore/${Mutations.SET_PREFERENCE}`,
              response
            );
          });
        })
        .catch(() => this.showNotifError("notif_update_fail"))
        .finally(() => (this.loadingSave = false));
    },
  },
  created() {
    const paramsFeature: RequestQueryParamsModel = {
      name: "key~feature_*",
    };
    const paramsSales: RequestQueryParamsModel = {
      name: "key~sales_*",
    };
    const paramsPurchases: RequestQueryParamsModel = {
      name: "key~purchase_*",
    };
    const paramsInventory: RequestQueryParamsModel = {
      name: "key~inventory_*",
    };
    const paramsAccountSetup: RequestQueryParamsModel = {
      name: "key~account_setup_*",
    };
    const promiseFeature: Promise<ResponsePreference[]> =
      settingsServices.getPreference(paramsFeature);
    const promiseSales: Promise<ResponsePreference[]> =
      settingsServices.getPreference(paramsSales);
    const promisePurchases: Promise<ResponsePreference[]> =
      settingsServices.getPreference(paramsPurchases);
    const promiseInventory: Promise<ResponsePreference[]> =
      settingsServices.getPreference(paramsInventory);
    const promiseAccountSetup: Promise<ResponsePreference[]> =
      settingsServices.getPreference(paramsAccountSetup);

    Promise.all([
      promiseFeature,
      promiseSales,
      promisePurchases,
      promiseInventory,
      promiseAccountSetup,
    ]).then(response => {
      response.map((dataMap, index) => {
        switch (index) {
          case 0:
            this.dataFeatureDetail = dataMap;
            this.handleSetFeature(dataMap);
            break;
          case 1:
            this.dataSalesDetail = dataMap;
            this.handleSetSales(dataMap);
            break;
          case 2:
            this.dataPurchaseDetail = dataMap;
            this.handleSetPurchase(dataMap);
            break;
          case 3:
            this.dataInventoryDetail = dataMap;
            this.handleSetInventory(dataMap);
            break;
          case 4:
            this.dataAccountSetupDetail = dataMap;
            this.handleSetAccountSetup(dataMap);
            break;
          default:
            break;
        }
      });
    });
  },
});
