import { OptionModel } from "@/models/constant/interface/common.interface";

export const OPTION_BASIC_FEATURE: OptionModel[] = [
    {
        label: 'Quotation and Purchase Order',
        value: 'purchase_quotation'
    },
    {
        label: 'Purchase Order Return',
        value: 'purchase_purchase_return'
    },
];