var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_preferences") } },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-tabs",
                {
                  attrs: {
                    type: "card",
                    "default-active-key": _vm.activeTabPane,
                    activeKey: _vm.activeTabPane
                  },
                  on: { change: _vm.handleChangeMenu }
                },
                [
                  _c(
                    "a-tab-pane",
                    {
                      key: "Feature",
                      staticClass: "bordertabs",
                      attrs: { tab: _vm.$t("lbl_feature") }
                    },
                    [
                      _c("Preferences-Feature", {
                        attrs: {
                          firstLoad: _vm.firstLoadComponentTab.feature,
                          dataFirstLoad: _vm.prefFeature
                        },
                        on: {
                          onChangeLoad: _vm.onChangeLoad,
                          onChange: _vm.onFeatureChange
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "Sales",
                      staticClass: "bordertabs",
                      attrs: { tab: _vm.$t("lbl_sales") }
                    },
                    [
                      _c("Preferences-Sales", {
                        attrs: {
                          firstLoad: _vm.firstLoadComponentTab.sales,
                          dataFirstLoad: _vm.prefSales
                        },
                        on: {
                          onChangeLoad: _vm.onChangeLoad,
                          onChange: _vm.onFeatureChange
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "Purchases",
                      staticClass: "bordertabs",
                      attrs: { tab: _vm.$t("lbl_purchases") }
                    },
                    [
                      _c("Preferences-Purchases", {
                        attrs: {
                          firstLoad: _vm.firstLoadComponentTab.purchase,
                          dataFirstLoad: _vm.prefPurchase
                        },
                        on: {
                          onChangeLoad: _vm.onChangeLoad,
                          onChange: _vm.onFeatureChange
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "Inventory",
                      staticClass: "bordertabs",
                      attrs: { tab: _vm.$t("lbl_inventory") }
                    },
                    [
                      _c("Preferences-Inventory", {
                        attrs: {
                          firstLoad: _vm.firstLoadComponentTab.inventory,
                          dataFirstLoad: _vm.prefInventory
                        },
                        on: {
                          onChangeLoad: _vm.onChangeLoad,
                          onChange: _vm.onFeatureChange
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "Account Setup",
                      staticClass: "bordertabs",
                      attrs: { tab: _vm.$t("lbl_account_setup") }
                    },
                    [
                      _c("Preferences-Account-Setup", {
                        attrs: {
                          firstLoad: _vm.firstLoadComponentTab.accountSetup,
                          dataFirstLoad: _vm.prefAccountSetup,
                          listIdCoa: _vm.listIdCoa
                        },
                        on: {
                          onChangeLoad: _vm.onChangeLoad,
                          onChange: _vm.onFeatureChange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-col", { attrs: { span: 24 } }, [
            _vm.activeTabPane !==
            _vm.columnTabPane[_vm.columnTabPane.length - 1]
              ? _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "a-space",
                      [
                        _vm.activeTabPane !== _vm.columnTabPane[0]
                          ? _c(
                              "a-button",
                              {
                                staticStyle: {
                                  background: "#ffd966",
                                  color: "black",
                                  border: "#ffd966"
                                },
                                on: { click: _vm.handleBack }
                              },
                              [
                                _c("a-icon", {
                                  staticStyle: { "vertical-align": "0" },
                                  attrs: { type: "left" }
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "a-button",
                          {
                            staticStyle: {
                              background: "#ffd966",
                              color: "black",
                              border: "#ffd966"
                            },
                            on: { click: _vm.handleNext }
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_next")) + " "),
                            _c("a-icon", {
                              staticStyle: { "vertical-align": "0" },
                              attrs: { type: "right" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.activeTabPane ===
            _vm.columnTabPane[_vm.columnTabPane.length - 1]
              ? _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "a-space",
                      [
                        _c(
                          "a-button",
                          {
                            staticStyle: {
                              background: "#ffd966",
                              color: "black",
                              border: "#ffd966"
                            },
                            on: { click: _vm.handleBack }
                          },
                          [
                            _c("a-icon", {
                              staticStyle: { "vertical-align": "0" },
                              attrs: { type: "left" }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                          ],
                          1
                        ),
                        _vm.$can("update", "preferences")
                          ? _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.loadingSave
                                },
                                on: { click: _vm.onSubmit }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }