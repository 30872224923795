import { OptionModel } from "@/models/constant/interface/common.interface";

export const OPTION_COSTING_METHOD: OptionModel[] = [
    {
        label: 'FIFO',
        value: 0
    },
    {
        label: 'AVERAGE',
        value: 1
    }
];

export const OPTION_BASIC_FEATURE: OptionModel[] = [
    {
        label: 'Show Product Stock',
        value: 'inventory_show_product_stock'
    },
    {
        label: 'Multi Warehouse',
        value: 'inventory_multi_warehouse'
    },
    {
        label: 'Multi UOM',
        value: 'inventory_multi_uom'
    },
    {
        label: 'Serial Number Product',
        value: 'inventory_serial_number'
    }
];
