import { OptionModel } from "@/models/constant/interface/common.interface";

export const OPTION_BASIC_FEATURE: OptionModel[] = [
    {
        label: 'Multi Branch',
        value: 'feature_multi_branch'
    },
    {
        label: 'Multi Currency',
        value: 'feature_multi_currency'
    },
    {
        label: 'Tax Inclusive',
        value: 'feature_tax_inclusive'
    },
    {
        label: 'Approval',
        value: 'feature_approval'
    },
    {
        label: 'Asset Management',
        value: 'feature_asset_management'
    },
];

export const OPTION_ADDITIONAL_FEATURE: OptionModel[] = [
    {
        label: 'Budgeting',
        value: 'budgeting',
        child: [
            {
                label: 'Branch',
                value: 'branch'
            },
            {
                label: 'Department',
                value: 'department'
            },
            {
                label: 'Proyek',
                value: 'proyek'
            },
        ]
    }
];

export const OPTION_DECIMAL_FORMAT: OptionModel[] = [
    {
        label: 'Indonesia (9.999,9)',
        value: 'id'
    },
    {
        label: 'Foreign (9,999.9)',
        value: 'other'
    }
];

export const OPTION_DECIMAL_NUMBER: OptionModel[] = [
    {
        label: '0,9',
        value: '1'
    },
    {
        label: '0,99',
        value: '2'
    },
    {
        label: '0,999',
        value: '3'
    }
];
