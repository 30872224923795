var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                { attrs: { bordered: false } },
                [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_default_account_description")) +
                        " "
                    )
                  ]),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("lbl_company")))]),
                          _c("p", { staticClass: "ml-2" }, [
                            _vm._v(_vm._s(_vm.$t("lbl_balance_sheet")))
                          ]),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 12,
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 12
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ml-4" },
                                    [
                                      _c(
                                        "a-form",
                                        _vm._b(
                                          {
                                            attrs: {
                                              layout: "vertical",
                                              form: _vm.form
                                            }
                                          },
                                          "a-form",
                                          _vm.formItemLayout,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_opening_balance_equity"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeBSOpening
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.balanceSheet
                                                        .account_setup_opening_balance_equity,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .balanceSheet,
                                                        "account_setup_opening_balance_equity",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.balanceSheet\n                            .account_setup_opening_balance_equity\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_retained_earning"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeBSRetained
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.balanceSheet
                                                        .account_setup_retained_earning,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .balanceSheet,
                                                        "account_setup_retained_earning",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.balanceSheet.account_setup_retained_earning\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_current_year_profit"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "filter-option": false,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeBSCurrentYearProfit
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.balanceSheet
                                                        .account_setup_current_year_profit,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .balanceSheet,
                                                        "account_setup_current_year_profit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.balanceSheet\n                            .account_setup_current_year_profit\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_current_month_profit"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "filter-option": false,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeBSCurrentMonthProfit
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.balanceSheet
                                                        .account_setup_current_month_profit,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .balanceSheet,
                                                        "account_setup_current_month_profit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.balanceSheet\n                            .account_setup_current_month_profit\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 12,
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 12
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ml-4" },
                                    [
                                      _c(
                                        "a-form",
                                        _vm._b(
                                          {
                                            attrs: {
                                              layout: "vertical",
                                              form: _vm.form
                                            }
                                          },
                                          "a-form",
                                          _vm.formItemLayout,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_exchange_rate_variance_gain"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeBSExchangeGain
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.balanceSheet
                                                        .account_setup_exchange_rate_variance_gain,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .balanceSheet,
                                                        "account_setup_exchange_rate_variance_gain",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.balanceSheet\n                            .account_setup_exchange_rate_variance_gain\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_exchange_rate_variance_loss"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeBSExchangeLoss
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.balanceSheet
                                                        .account_setup_exchange_rate_variance_loss,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .balanceSheet,
                                                        "account_setup_exchange_rate_variance_loss",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.balanceSheet\n                            .account_setup_exchange_rate_variance_loss\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("p", [_vm._v("Sales And Purchase")]),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 12,
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 12
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ml-4" },
                                    [
                                      _c(
                                        "a-form",
                                        _vm._b(
                                          {
                                            attrs: {
                                              layout: "vertical",
                                              form: _vm.form
                                            }
                                          },
                                          "a-form",
                                          _vm.formItemLayout,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_sales_revenue"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPSRevenue
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_sales_revenue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_sales_revenue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase.account_setup_sales_revenue\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_sales_discount"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPSDiscount
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_sales_discount,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_sales_discount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase.account_setup_sales_discount\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_sales_return"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPSReturn
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_sales_return,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_sales_return",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase.account_setup_sales_return\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_sales_delivery"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPSDelivery
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_sales_delivery,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_sales_delivery",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase.account_setup_sales_delivery\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_unearned_revenue"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPURevenue
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_unearned_revenue,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_unearned_revenue",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase.account_setup_unearned_revenue\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_unbilled_sales"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change: _vm.onChangeSPUSales
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_unbilled_sales,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_unbilled_sales",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase.account_setup_unbilled_sales\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_unbilled_receivables"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPUReceivables
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_unbilled_receivables,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_unbilled_receivables",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase\n                            .account_setup_unbilled_receivables\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: { label: "Sales Bad Debt" }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPSalesBadDebt
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_sales_bad_debt,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_sales_bad_debt",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase.account_setup_sales_bad_debt\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: "Purchase Bad Debt"
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPPurchaseBadDebt
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_purchase_bad_debt,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_purchase_bad_debt",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase\n                            .account_setup_purchase_bad_debt\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 12,
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 12
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ml-4" },
                                    [
                                      _c(
                                        "a-form",
                                        _vm._b(
                                          {
                                            attrs: {
                                              layout: "vertical",
                                              form: _vm.form
                                            }
                                          },
                                          "a-form",
                                          _vm.formItemLayout,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_purchase_cogs"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change: _vm.onChangeSPPCOGS
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_purchase_cogs,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_purchase_cogs",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase.account_setup_purchase_cogs\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_purchase_shipping"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPPShipping
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_purchase_shipping,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_purchase_shipping",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase\n                            .account_setup_purchase_shipping\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_purchase_return"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPPReturn
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_purchase_return,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_purchase_return",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase.account_setup_purchase_return\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_unbilled_payable"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPUPayable
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_unbilled_payables,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_unbilled_payables",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase\n                            .account_setup_unbilled_payables\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_rounding_account_gain"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPRoundingAccountGain
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_rounding_account_gain,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_rounding_account_gain",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase\n                            .account_setup_rounding_account_gain\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_rounding_account_loss"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPRoundingAccountLoss
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_rounding_account_loss,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_rounding_account_loss",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase\n                            .account_setup_rounding_account_loss\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_purchase_price_variance"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPPPriceVariance
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_purchase_price_variance,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_purchase_price_variance",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase\n                            .account_setup_purchase_price_variance\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_purchase_discount"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPDiscount
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_purchase_discount,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_purchase_discount",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase\n                            .account_setup_purchase_discount\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_prepayment_dealer"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeSPPrepaymentDealer
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.salesPurchase
                                                        .account_setup_prepayment_dealer,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref
                                                          .salesPurchase,
                                                        "account_setup_prepayment_dealer",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.salesPurchase\n                            .account_setup_prepayment_dealer\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("lbl_ar_ap")))]),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          layout: "vertical",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          staticStyle: { margin: "0" },
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_account_receivable"
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              attrs: {
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                allowClear: true,
                                                placeholder: _vm.$t(
                                                  "lbl_select_placeholder"
                                                )
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.getListOfCoa(
                                                    value,
                                                    null
                                                  )
                                                },
                                                change:
                                                  _vm.onChangeARAPAReceivables
                                              },
                                              model: {
                                                value:
                                                  _vm.valPref.arap
                                                    .account_setup_account_receiviables,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.valPref.arap,
                                                    "account_setup_account_receiviables",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        valPref.arap.account_setup_account_receiviables\n                      "
                                              }
                                            },
                                            _vm._l(_vm.dataCoa.data, function(
                                              data,
                                              index
                                            ) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: index,
                                                  attrs: { value: data.id }
                                                },
                                                [
                                                  _c(
                                                    "a-tooltip",
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "title" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.code +
                                                                  " " +
                                                                  data.description
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.code +
                                                              " " +
                                                              data.description
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          staticStyle: { margin: "0" },
                                          attrs: {
                                            label: _vm.$t("lbl_account_payable")
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              attrs: {
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                allowClear: true,
                                                placeholder: _vm.$t(
                                                  "lbl_select_placeholder"
                                                )
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.getListOfCoa(
                                                    value,
                                                    null
                                                  )
                                                },
                                                change:
                                                  _vm.onChangeARAPAPayables
                                              },
                                              model: {
                                                value:
                                                  _vm.valPref.arap
                                                    .account_setup_account_payables,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.valPref.arap,
                                                    "account_setup_account_payables",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "valPref.arap.account_setup_account_payables"
                                              }
                                            },
                                            _vm._l(_vm.dataCoa.data, function(
                                              data,
                                              index
                                            ) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: index,
                                                  attrs: { value: data.id }
                                                },
                                                [
                                                  _c(
                                                    "a-tooltip",
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "title" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.code +
                                                                  " " +
                                                                  data.description
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.code +
                                                              " " +
                                                              data.description
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          staticStyle: { margin: "0" },
                                          attrs: {
                                            label: _vm.$t(
                                              "lbl_invoice_price_variance"
                                            )
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              attrs: {
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                allowClear: true,
                                                placeholder: _vm.$t(
                                                  "lbl_select_placeholder"
                                                )
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.getListOfCoa(
                                                    value,
                                                    null
                                                  )
                                                },
                                                change:
                                                  _vm.onChangeARAPAVariance
                                              },
                                              model: {
                                                value:
                                                  _vm.valPref.arap
                                                    .account_setup_invoice_price_variance,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.valPref.arap,
                                                    "account_setup_invoice_price_variance",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        valPref.arap.account_setup_invoice_price_variance\n                      "
                                              }
                                            },
                                            _vm._l(_vm.dataCoa.data, function(
                                              data,
                                              index
                                            ) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: index,
                                                  attrs: { value: data.id }
                                                },
                                                [
                                                  _c(
                                                    "a-tooltip",
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "title" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.code +
                                                                  " " +
                                                                  data.description
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.code +
                                                              " " +
                                                              data.description
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ml-4" },
                                [
                                  _c(
                                    "a-form",
                                    _vm._b(
                                      {
                                        attrs: {
                                          layout: "vertical",
                                          form: _vm.form
                                        }
                                      },
                                      "a-form",
                                      _vm.formItemLayout,
                                      false
                                    ),
                                    [
                                      _c(
                                        "a-form-item",
                                        {
                                          staticStyle: { margin: "0" },
                                          attrs: {
                                            label: _vm.$t("lbl_prepayment_ar")
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              attrs: {
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                allowClear: true,
                                                placeholder: _vm.$t(
                                                  "lbl_select_placeholder"
                                                )
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.getListOfCoa(
                                                    value,
                                                    null
                                                  )
                                                },
                                                change:
                                                  _vm.onChangeSPPrepaymentAr
                                              },
                                              model: {
                                                value:
                                                  _vm.valPref.arap
                                                    .account_setup_prepayment_ar,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.valPref.arap,
                                                    "account_setup_prepayment_ar",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "valPref.arap.account_setup_prepayment_ar"
                                              }
                                            },
                                            _vm._l(_vm.dataCoa.data, function(
                                              data,
                                              index
                                            ) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: index,
                                                  attrs: { value: data.id }
                                                },
                                                [
                                                  _c(
                                                    "a-tooltip",
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "title" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.code +
                                                                  " " +
                                                                  data.description
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.code +
                                                              " " +
                                                              data.description
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-form-item",
                                        {
                                          staticStyle: { margin: "0" },
                                          attrs: {
                                            label: _vm.$t("lbl_prepayment_ap")
                                          }
                                        },
                                        [
                                          _c(
                                            "a-select",
                                            {
                                              attrs: {
                                                showSearch: true,
                                                "option-filter-prop":
                                                  "children",
                                                "filter-option":
                                                  _vm.filterOption,
                                                allowClear: true,
                                                placeholder: _vm.$t(
                                                  "lbl_select_placeholder"
                                                )
                                              },
                                              on: {
                                                search: function(value) {
                                                  return _vm.getListOfCoa(
                                                    value,
                                                    null
                                                  )
                                                },
                                                change:
                                                  _vm.onChangeSPPrepaymentAp
                                              },
                                              model: {
                                                value:
                                                  _vm.valPref.arap
                                                    .account_setup_prepayment_ap,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.valPref.arap,
                                                    "account_setup_prepayment_ap",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "valPref.arap.account_setup_prepayment_ap"
                                              }
                                            },
                                            _vm._l(_vm.dataCoa.data, function(
                                              data,
                                              index
                                            ) {
                                              return _c(
                                                "a-select-option",
                                                {
                                                  key: index,
                                                  attrs: { value: data.id }
                                                },
                                                [
                                                  _c(
                                                    "a-tooltip",
                                                    [
                                                      _c(
                                                        "template",
                                                        { slot: "title" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.code +
                                                                  " " +
                                                                  data.description
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.code +
                                                              " " +
                                                              data.description
                                                          ) +
                                                          " "
                                                      )
                                                    ],
                                                    2
                                                  )
                                                ],
                                                1
                                              )
                                            }),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("lbl_inventory")))]),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 12,
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 12
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ml-4" },
                                    [
                                      _c(
                                        "a-form",
                                        _vm._b(
                                          {
                                            attrs: {
                                              layout: "vertical",
                                              form: _vm.form
                                            }
                                          },
                                          "a-form",
                                          _vm.formItemLayout,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t("lbl_inventory")
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change: _vm.onChangeInv
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.inventory
                                                        .account_setup_inventory,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref.inventory,
                                                        "account_setup_inventory",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "valPref.inventory.account_setup_inventory"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_inventory_non_stock"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeInvNonStock
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.inventory
                                                        .account_setup_inventory_non_stock,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref.inventory,
                                                        "account_setup_inventory_non_stock",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.inventory.account_setup_inventory_non_stock\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_inventory_waste"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change: _vm.onChangeInvWaste
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.inventory
                                                        .account_setup_inventory_waste,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref.inventory,
                                                        "account_setup_inventory_waste",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.inventory.account_setup_inventory_waste\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 12,
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 12
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ml-4" },
                                    [
                                      _c(
                                        "a-form",
                                        _vm._b(
                                          {
                                            attrs: {
                                              layout: "vertical",
                                              form: _vm.form
                                            }
                                          },
                                          "a-form",
                                          _vm.formItemLayout,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_stock_adjustment"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeInvStockAdj
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.inventory
                                                        .account_setup_stock_adjustment,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref.inventory,
                                                        "account_setup_stock_adjustment",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.inventory.account_setup_stock_adjustment\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t(
                                                  "lbl_stock_variance"
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change:
                                                      _vm.onChangeInvStockVariance
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.inventory
                                                        .account_setup_stock_variance,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref.inventory,
                                                        "account_setup_stock_variance",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          valPref.inventory.account_setup_stock_variance\n                        "
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.$t("lbl_tax")))]),
                          _c(
                            "a-row",
                            { attrs: { gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    span: 12,
                                    xs: 24,
                                    sm: 24,
                                    md: 24,
                                    lg: 12
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ml-4" },
                                    [
                                      _c(
                                        "a-form",
                                        _vm._b(
                                          {
                                            attrs: {
                                              layout: "vertical",
                                              form: _vm.form
                                            }
                                          },
                                          "a-form",
                                          _vm.formItemLayout,
                                          false
                                        ),
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t("lbl_vat_in")
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change: _vm.onChangeVatIn
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.tax
                                                        .account_setup_vat_in,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref.tax,
                                                        "account_setup_vat_in",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "valPref.tax.account_setup_vat_in"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              staticStyle: { margin: "0" },
                                              attrs: {
                                                label: _vm.$t("lbl_vat_out")
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  attrs: {
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    allowClear: true,
                                                    placeholder: _vm.$t(
                                                      "lbl_select_placeholder"
                                                    )
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getListOfCoa(
                                                        value,
                                                        null
                                                      )
                                                    },
                                                    change: _vm.onChangeVatOut
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.valPref.tax
                                                        .account_setup_vat_out,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.valPref.tax,
                                                        "account_setup_vat_out",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "valPref.tax.account_setup_vat_out"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCoa.data,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.code +
                                                                        " " +
                                                                        data.description
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.code +
                                                                    " " +
                                                                    data.description
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }