var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-space",
        { attrs: { direction: "vertical" } },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("lbl_basic_feature")))]),
          _c(
            "a-row",
            [
              _c("a-col", { attrs: { span: 14, xs: 8, sm: 14, md: 14 } }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("lbl_basic_invoice_term")))])
              ]),
              _c(
                "a-col",
                { attrs: { span: 8, xs: 14, sm: 14, md: 8 } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: _vm.$t("lbl_select_placeholder") },
                      on: { change: _vm.onChangeTop },
                      model: {
                        value: _vm.valPref.sales_top,
                        callback: function($$v) {
                          _vm.$set(_vm.valPref, "sales_top", $$v)
                        },
                        expression: "valPref.sales_top"
                      }
                    },
                    _vm._l(_vm.optBasicInvoiceTerm, function(opt) {
                      return _c(
                        "a-select-option",
                        { key: opt.id, attrs: { value: opt.id } },
                        [_vm._v(_vm._s(opt.value))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c("a-checkbox-group", {
                staticClass: "check-basic",
                attrs: { options: _vm.optBasicFeature },
                on: { change: _vm.onChecked },
                model: {
                  value: _vm.valPref.basic,
                  callback: function($$v) {
                    _vm.$set(_vm.valPref, "basic", $$v)
                  },
                  expression: "valPref.basic"
                }
              })
            ],
            1
          ),
          _c(
            "a-row",
            { staticStyle: { "margin-top": "12px" } },
            [
              _c("a-col", { attrs: { span: 14, xs: 8, sm: 14, md: 14 } }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("lbl_sales_commision_counting_from")))
                ])
              ]),
              _c(
                "a-col",
                { attrs: { span: 8, xs: 14, sm: 14, md: 8 } },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: _vm.$t("lbl_select_placeholder") },
                      on: { change: _vm.onChangeCommision },
                      model: {
                        value: _vm.valPref.sales_commision_counting_from,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.valPref,
                            "sales_commision_counting_from",
                            $$v
                          )
                        },
                        expression: "valPref.sales_commision_counting_from"
                      }
                    },
                    _vm._l(_vm.optSalesCommision, function(opt) {
                      return _c(
                        "a-select-option",
                        { key: opt.id, attrs: { value: opt.id } },
                        [_vm._v(_vm._s(opt.value))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }