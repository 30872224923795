var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                { attrs: { bordered: false } },
                [
                  _c("a-space", { attrs: { direction: "vertical" } }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("lbl_basic_feature")))]),
                    _c(
                      "div",
                      [
                        _c(
                          "a-space",
                          { attrs: { size: 90 } },
                          [
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(_vm._s(_vm.$t("lbl_basic_invoice_term")))
                            ]),
                            _c(
                              "a-select",
                              {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  placeholder: _vm.$t("lbl_select_placeholder")
                                },
                                on: { change: _vm.onChangeTop },
                                model: {
                                  value: _vm.valPref.purchase_top,
                                  callback: function($$v) {
                                    _vm.$set(_vm.valPref, "purchase_top", $$v)
                                  },
                                  expression: "valPref.purchase_top"
                                }
                              },
                              _vm._l(_vm.optBasicInvoiceTerm, function(opt) {
                                return _c(
                                  "a-select-option",
                                  { key: opt.id, attrs: { value: opt.id } },
                                  [_vm._v(_vm._s(opt.value))]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("a-checkbox-group", {
                          staticClass: "check-basic",
                          attrs: { options: _vm.optBasicFeature },
                          on: { change: _vm.onChecked },
                          model: {
                            value: _vm.valPref.basic,
                            callback: function($$v) {
                              _vm.$set(_vm.valPref, "basic", $$v)
                            },
                            expression: "valPref.basic"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }