var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16], type: "flex" } },
    [
      _c(
        "a-col",
        { attrs: { span: 12 } },
        [
          _c("a-card", { attrs: { bordered: false } }, [
            _c("p", {}, [_vm._v(_vm._s(_vm.$t("lbl_basic_feature")))]),
            _c(
              "div",
              [
                _c("a-checkbox-group", {
                  staticClass: "check-basic",
                  attrs: { options: _vm.optBasicFeatures },
                  on: { change: _vm.onChangeBasic },
                  model: {
                    value: _vm.valPref.basic,
                    callback: function($$v) {
                      _vm.$set(_vm.valPref, "basic", $$v)
                    },
                    expression: "valPref.basic"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 12 } },
        [
          _c(
            "a-card",
            { attrs: { bordered: false } },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("lbl_additional_feature")))]),
              _vm._l(_vm.optAdditionalFeatures, function(opt, index) {
                return [
                  _c(
                    "a-checkbox",
                    {
                      key: opt.value,
                      attrs: {
                        disabled: "",
                        indeterminate: _vm.adtIndeterminate,
                        checked: _vm.adtCheckAll
                      },
                      on: { change: _vm.onCheckAllChange }
                    },
                    [_vm._v(_vm._s(opt.label))]
                  ),
                  _c("a-checkbox-group", {
                    key: index,
                    staticClass: "check-additional",
                    attrs: { disabled: "", options: opt.child },
                    on: { change: _vm.onChangeAdditional },
                    model: {
                      value: _vm.valPref.additional,
                      callback: function($$v) {
                        _vm.$set(_vm.valPref, "additional", $$v)
                      },
                      expression: "valPref.additional"
                    }
                  })
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 12 } },
        [
          _c(
            "a-card",
            { attrs: { bordered: false } },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("lbl_currency_format")))]),
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("lbl_base_currency")))])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            showSearch: true,
                            allowClear: true,
                            placeholder: _vm.$t("lbl_select_placeholder")
                          },
                          on: {
                            change: _vm.onChangeBaseCurrency,
                            search: function(value) {
                              return _vm.handleSearchCurrency(value)
                            }
                          },
                          model: {
                            value: _vm.valPref.currency.feature_base_currency,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.valPref.currency,
                                "feature_base_currency",
                                $$v
                              )
                            },
                            expression: "valPref.currency.feature_base_currency"
                          }
                        },
                        _vm._l(_vm.optBaseCurrency, function(opt) {
                          return _c(
                            "a-select-option",
                            { key: opt.id, attrs: { value: opt.id } },
                            [_vm._v(" " + _vm._s(opt.currencyCode) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("lbl_decimal_format")))])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: _vm.$t("lbl_select_placeholder")
                          },
                          on: { change: _vm.onChangeFormat },
                          model: {
                            value: _vm.valPref.currency.feature_decimal_format,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.valPref.currency,
                                "feature_decimal_format",
                                $$v
                              )
                            },
                            expression:
                              "valPref.currency.feature_decimal_format"
                          }
                        },
                        _vm._l(_vm.optDecimalFormat, function(opt) {
                          return _c(
                            "a-select-option",
                            { key: opt.id, attrs: { value: opt.id } },
                            [_vm._v(" " + _vm._s(opt.value) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("lbl_decimal_option")))])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: _vm.$t("lbl_select_placeholder")
                          },
                          on: { change: _vm.onChangeOption },
                          model: {
                            value: _vm.valPref.currency.feature_decimal_point,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.valPref.currency,
                                "feature_decimal_point",
                                $$v
                              )
                            },
                            expression: "valPref.currency.feature_decimal_point"
                          }
                        },
                        _vm._l(_vm.optDecimalNumber, function(opt) {
                          return _c(
                            "a-select-option",
                            { key: opt.id, attrs: { value: opt.value } },
                            [_vm._v(" " + _vm._s(opt.value) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("lbl_discount_before_after_tax")))
                    ])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            placeholder: _vm.$t("lbl_select_placeholder")
                          },
                          on: { change: _vm.onChangeBeforeAfterTax },
                          model: {
                            value:
                              _vm.valPref.currency
                                .feature_discount_before_after_tax,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.valPref.currency,
                                "feature_discount_before_after_tax",
                                $$v
                              )
                            },
                            expression:
                              "valPref.currency.feature_discount_before_after_tax"
                          }
                        },
                        _vm._l(_vm.optionDiscount, function(opt) {
                          return _c(
                            "a-select-option",
                            { key: opt.id, attrs: { value: opt.id } },
                            [_vm._v(" " + _vm._s(opt.value) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 12 } },
        [
          _c(
            "a-card",
            { attrs: { bordered: false } },
            [
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 8 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("lbl_invoice_ar_assignee")))])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c("SelectEmployee", {
                        staticClass: "w-100",
                        attrs: {
                          value: _vm.preferenceField.invoiceArAssignee.label
                        },
                        on: { "update:meta": _vm.onChangeAssignee }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c(
            "a-card",
            { attrs: { bordered: false } },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("lbl_tax_rate")))]),
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 4 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("lbl_sales_tax_rate")))])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            showSearch: true,
                            allowClear: true,
                            placeholder: _vm.$t("lbl_select_placeholder")
                          },
                          on: {
                            change: _vm.onChangeSalesTaxRate,
                            search: function(value) {
                              return _vm.handleSearchSalesTaxRate(value)
                            }
                          },
                          model: {
                            value: _vm.valPref.taxRate.feature_sales_tax_rate,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.valPref.taxRate,
                                "feature_sales_tax_rate",
                                $$v
                              )
                            },
                            expression: "valPref.taxRate.feature_sales_tax_rate"
                          }
                        },
                        _vm._l(_vm.optSalesTaxRate, function(opt) {
                          return _c(
                            "a-select-option",
                            { key: opt.id, attrs: { value: opt.id } },
                            [_vm._v(" " + _vm._s(opt.code) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { span: 4, xs: 8, sm: 8, md: 4 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.$t("lbl_purchase_tax_rate")))])
                  ]),
                  _c(
                    "a-col",
                    { attrs: { span: 14 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            showSearch: true,
                            allowClear: true,
                            placeholder: _vm.$t("lbl_select_placeholder")
                          },
                          on: {
                            change: _vm.onChangePurchaseTaxRate,
                            search: function(value) {
                              return _vm.handleSearchPurchaseTaxRate(value)
                            }
                          },
                          model: {
                            value:
                              _vm.valPref.taxRate.feature_purchase_tax_rate,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.valPref.taxRate,
                                "feature_purchase_tax_rate",
                                $$v
                              )
                            },
                            expression:
                              "valPref.taxRate.feature_purchase_tax_rate"
                          }
                        },
                        _vm._l(_vm.optSalesPurchaseRate, function(opt) {
                          return _c(
                            "a-select-option",
                            { key: opt.id, attrs: { value: opt.id } },
                            [_vm._v(" " + _vm._s(opt.code) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }