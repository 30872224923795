






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListOfCoa } from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";
import Vue, { PropType } from "vue";
import { COMPONENT_NAME } from "./Preferences.vue";
export interface IPrefAccountSetup {
  balanceSheet: {
    account_setup_opening_balance_equity: string;
    account_setup_retained_earning: string;
    account_setup_exchange_rate_variance_gain: string;
    account_setup_exchange_rate_variance_loss: string;
    account_setup_current_year_profit: string;
    account_setup_current_month_profit: string;
  };
  salesPurchase: {
    account_setup_sales_revenue: string;
    account_setup_sales_discount: string;
    account_setup_sales_return: string;
    account_setup_sales_delivery: string;
    account_setup_unearned_revenue: string;
    account_setup_unbilled_sales: string;
    account_setup_unbilled_receivables: string;
    account_setup_sales_bad_debt: string;
    account_setup_purchase_bad_debt: string;
    account_setup_purchase_cogs: string;
    account_setup_purchase_shipping: string;
    account_setup_purchase_return: string;
    account_setup_unbilled_payables: string;
    account_setup_rounding_account_gain: string;
    account_setup_rounding_account_loss: string;
    account_setup_purchase_price_variance: string;
    account_setup_purchase_discount: string;
    account_setup_prepayment_dealer: string;
  };
  arap: {
    account_setup_account_receiviables: string;
    account_setup_account_payables: string;
    account_setup_invoice_price_variance: string;
    account_setup_prepayment_ap: string;
    account_setup_prepayment_ar: string;
  };
  inventory: {
    account_setup_inventory: string;
    account_setup_inventory_non_stock: string;
    account_setup_inventory_waste: string;
    account_setup_stock_adjustment: string;
    account_setup_stock_variance: string;
  };
  tax: {
    account_setup_vat_in: string;
    account_setup_vat_out: string;
  };
}
export default Vue.extend({
  name: "PreferencesAccountSetup",
  props: {
    firstLoad: Boolean,
    listIdCoa: [] as PropType<string[]>,
    dataFirstLoad: {} as PropType<IPrefAccountSetup>,
  },
  watch: {
    dataFirstLoad: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstLoad && newValue) {
          this.valPref.balanceSheet = newValue.balanceSheet;
          this.valPref.salesPurchase = newValue.salesPurchase;
          this.valPref.arap = newValue.arap;
          this.valPref.inventory = newValue.inventory;
          this.valPref.tax = newValue.tax;
          this.$emit("onChangeLoad", {
            name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
            val: true,
          });
        }
      },
    },
    dataCoa: {
      immediate: true,
      deep: true,
      handler(newValue) {
        const listIdNotRepeat: string[] = [];
        const listPromiseCoa: Promise<ResponseListOfCoa>[] = [];

        if (
          !this.firstLoadAccountSetup &&
          newValue &&
          newValue.data &&
          this.listIdCoa &&
          this.listIdCoa.length > 0
        ) {
          this.listIdCoa.forEach(dataForeach => {
            let flag = false;
            newValue.data.forEach(dataLoopCoa => {
              if (dataForeach === dataLoopCoa.id) {
                flag = true;
              }
            });

            if (
              !flag &&
              dataForeach &&
              !listIdNotRepeat.includes(dataForeach)
            ) {
              listIdNotRepeat.push(dataForeach);
            }
          });

          this.firstLoadAccountSetup = true;

          listIdNotRepeat.forEach(id => {
            const params = {
              page: 0,
              limit: 10,
              search: `isParent~false_AND_secureId~${id}`,
            } as RequestQueryParamsModel;
            listPromiseCoa.push(settingsServices.listOfCoa(params, ""));
          });

          Promise.all(listPromiseCoa).then(response => {
            response.forEach(dataForeach => {
              this.dataCoa = {
                ...this.dataCoa,
                data: [...this.dataCoa.data, ...dataForeach.data],
              };
            });
          });
        }
      },
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "preferenceAccountSetup" }),
      loadingCoa: false as boolean,
      dataCoa: {} as ResponseListOfCoa,
      firstLoadAccountSetup: false as boolean,
      valPref: {
        balanceSheet: {
          account_setup_opening_balance_equity: "" as string,
          account_setup_retained_earning: "" as string,
          account_setup_exchange_rate_variance_gain: "" as string,
          account_setup_exchange_rate_variance_loss: "" as string,
          account_setup_current_year_profit: "",
          account_setup_current_month_profit: "",
        },
        salesPurchase: {
          account_setup_sales_revenue: "" as string,
          account_setup_sales_discount: "" as string,
          account_setup_sales_return: "" as string,
          account_setup_sales_delivery: "" as string,
          account_setup_unearned_revenue: "" as string,
          account_setup_unbilled_sales: "" as string,
          account_setup_unbilled_receivables: "" as string,
          account_setup_sales_bad_debt: "" as string,
          account_setup_purchase_bad_debt: "" as string,
          account_setup_purchase_cogs: "" as string,
          account_setup_purchase_shipping: "" as string,
          account_setup_purchase_return: "" as string,
          account_setup_unbilled_payables: "" as string,
          account_setup_rounding_account_gain: "" as string,
          account_setup_rounding_account_loss: "" as string,
          account_setup_purchase_price_variance: "" as string,
          account_setup_purchase_discount: "" as string,
          account_setup_prepayment_dealer: "" as string,
        },
        arap: {
          account_setup_account_receiviables: "" as string,
          account_setup_account_payables: "" as string,
          account_setup_invoice_price_variance: "" as string,
          account_setup_prepayment_ap: "" as string,
          account_setup_prepayment_ar: "" as string,
        },
        inventory: {
          account_setup_inventory: "" as string,
          account_setup_inventory_non_stock: "" as string,
          account_setup_inventory_waste: "" as string,
          account_setup_stock_adjustment: "" as string,
          account_setup_stock_variance: "" as string,
        },
        tax: {
          account_setup_vat_in: "" as string,
          account_setup_vat_out: "" as string,
        },
      } as IPrefAccountSetup,
    };
  },
  methods: {
    getListOfCoa(value, id?) {
      let params = {
        page: 0,
        limit: 10,
        search: `isParent~false`,
      } as RequestQueryParamsModel;
      if (value)
        params.search =
          `code~*${value}*_OR_description~*${value}*_AND_` + params.search;
      if (id) params.search += `_AND_secureId~${id}`;

      // list coa
      this.loadingCoa = true;
      settingsServices
        .listOfCoa(params, "")
        .then(data => {
          if (id) {
            this.dataCoa = {
              ...this.dataCoa,
              data: [...this.dataCoa.data, ...data.data],
            };
          } else {
            this.dataCoa = data;
          }
        })
        .finally(() => {
          this.loadingCoa = false;
        });
    },
    onChangeBSOpening(value): void {
      this.valPref.balanceSheet.account_setup_opening_balance_equity = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeBSRetained(value): void {
      this.valPref.balanceSheet.account_setup_retained_earning = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeBSExchangeGain(value): void {
      this.valPref.balanceSheet.account_setup_exchange_rate_variance_gain =
        value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeBSExchangeLoss(value): void {
      this.valPref.balanceSheet.account_setup_exchange_rate_variance_loss =
        value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPSRevenue(value): void {
      this.valPref.salesPurchase.account_setup_sales_revenue = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPSDiscount(value): void {
      this.valPref.salesPurchase.account_setup_sales_discount = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPSReturn(value): void {
      this.valPref.salesPurchase.account_setup_sales_return = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPSDelivery(value): void {
      this.valPref.salesPurchase.account_setup_sales_delivery = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPURevenue(value): void {
      this.valPref.salesPurchase.account_setup_unearned_revenue = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPUSales(value): void {
      this.valPref.salesPurchase.account_setup_unbilled_sales = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPUReceivables(value): void {
      this.valPref.salesPurchase.account_setup_unbilled_receivables = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPSalesBadDebt(value): void {
      this.valPref.salesPurchase.account_setup_sales_bad_debt = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPPurchaseBadDebt(value): void {
      this.valPref.salesPurchase.account_setup_purchase_bad_debt = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPPCOGS(value): void {
      this.valPref.salesPurchase.account_setup_purchase_cogs = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPPShipping(value): void {
      this.valPref.salesPurchase.account_setup_purchase_shipping = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPPReturn(value): void {
      this.valPref.salesPurchase.account_setup_purchase_return = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPUPayable(value): void {
      this.valPref.salesPurchase.account_setup_unbilled_payables = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPRoundingAccountGain(value): void {
      this.valPref.salesPurchase.account_setup_rounding_account_gain = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPRoundingAccountLoss(value): void {
      this.valPref.salesPurchase.account_setup_rounding_account_loss = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPPPriceVariance(value): void {
      this.valPref.salesPurchase.account_setup_purchase_price_variance = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPDiscount(value): void {
      this.valPref.salesPurchase.account_setup_purchase_discount = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPPrepaymentDealer(value): void {
      this.valPref.salesPurchase.account_setup_prepayment_dealer = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeARAPAReceivables(value): void {
      this.valPref.arap.account_setup_account_receiviables = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeARAPAPayables(value): void {
      this.valPref.arap.account_setup_account_payables = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeARAPAVariance(value): void {
      this.valPref.arap.account_setup_invoice_price_variance = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPPrepaymentAp(value): void {
      this.valPref.arap.account_setup_prepayment_ap = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeSPPrepaymentAr(value): void {
      this.valPref.arap.account_setup_prepayment_ar = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeInv(value): void {
      this.valPref.inventory.account_setup_inventory = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeInvNonStock(value): void {
      this.valPref.inventory.account_setup_inventory_non_stock = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeInvWaste(value): void {
      this.valPref.inventory.account_setup_inventory_waste = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeInvStockAdj(value): void {
      this.valPref.inventory.account_setup_stock_adjustment = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeInvStockVariance(value): void {
      this.valPref.inventory.account_setup_stock_variance = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeVatIn(value): void {
      this.valPref.tax.account_setup_vat_in = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeVatOut(value): void {
      this.valPref.tax.account_setup_vat_out = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeBSCurrentYearProfit(value): void {
      this.valPref.balanceSheet.account_setup_current_year_profit = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    onChangeBSCurrentMonthProfit(value): void {
      this.valPref.balanceSheet.account_setup_current_month_profit = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_ACCOUNT_SETUP,
        val: this.valPref,
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  created() {
    this.getListOfCoa("", null);
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
      };
    },
  },
});
