import { OptionModel } from "@/models/constant/interface/common.interface";

export const OPTION_BASIC_INVOICE_TERM: OptionModel[] = [
    {
        label: '30',
        value: 30
    },
    {
        label: '60',
        value: 60
    },
    {
        label: '90',
        value: 90
    }
];

export const OPTION_BASIC_FEATURE: OptionModel[] = [
    {
        label: 'Quotation and Sales Order',
        value: 'sales_quotation_and_sales_order'
    },
    {
        label: 'Sales Order Return',
        value: 'sales_order_return'
    },
    {
        label: 'Join Invoice (Tukar Faktur)',
        value: 'sales_join_invoice'
    },
    {
        label: 'Disable Sell When Not Enough Qty',
        value: 'sales_disable_sell_when_not_enough_qty'
    },
];

export const OPTION_SALES_COMMISION: OptionModel[] = [
    {
        label: 'All Invoices',
        value: 0
    },
    {
        label: 'Paid Invoices',
        value: 1
    }
];
