
























































import { ResponseListMaster } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterServices } from "@/services/master.service";
import Vue, { PropType } from "vue";
import { OPTION_BASIC_FEATURE } from "./constant/optionsales.constant";
import { COMPONENT_NAME } from "./Preferences.vue";
export interface IPrefSales {
  sales_top: string;
  basic: string[];
  sales_commision_counting_from: string;
}
export default Vue.extend({
  name: "PreferencesSales",
  props: {
    firstLoad: Boolean,
    dataFirstLoad: {} as PropType<IPrefSales>,
  },
  watch: {
    dataFirstLoad: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstLoad && newValue) {
          this.valPref.sales_top = newValue.sales_top;
          this.valPref.basic = newValue.basic;
          this.valPref.sales_commision_counting_from =
            newValue.sales_commision_counting_from;
          this.$emit("onChangeLoad", {
            name: COMPONENT_NAME.PREF_SALES,
            val: true,
          });
        }
      },
    },
  },
  data() {
    return {
      optBasicInvoiceTerm: [] as ResponseListMaster[],
      optBasicFeature: OPTION_BASIC_FEATURE,
      optSalesCommision: [] as ResponseListMaster[],
      salesCheckedList: [],
      valPref: {
        sales_top: "0",
        basic: [] as string[],
        sales_commision_counting_from: "" as string,
      } as IPrefSales,
    };
  },
  methods: {
    onChangeCommision(value): void {
      this.valPref.sales_commision_counting_from = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_SALES,
        val: this.valPref,
      });
    },
    onChangeTop(value): void {
      this.valPref.sales_top = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_SALES,
        val: this.valPref,
      });
    },
    onChecked(checkedValues): void {
      this.valPref.basic = checkedValues;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_SALES,
        val: this.valPref,
      });
    },
  },
  created() {
    const paramsTOP: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "TOP",
    };
    masterServices.listMaster(paramsTOP).then(responseTop => {
      this.optBasicInvoiceTerm = responseTop;
    });
    const paramsSalesCommision: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "SALES_COMMISION",
    };
    masterServices
      .listMaster(paramsSalesCommision)
      .then(responseSalesCommision => {
        this.optSalesCommision = responseSalesCommision;
      });
  },
});
