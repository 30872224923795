










































































































































































































import SelectEmployee from "@/components/custom/select/EmployeeSelect.vue";
import { debounceProcess } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { ListContactDataDTO } from "@/models/interface/ContactDataDto.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataMasterCurrency,
  DataTax,
} from "@/models/interface/settings.interface";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import { PreferenceField } from "@/store/preference.store";
import Vue, { PropType } from "vue";
import { mapMutations, mapState } from "vuex";
import {
  OPTION_ADDITIONAL_FEATURE,
  OPTION_BASIC_FEATURE,
} from "./constant/optionfeature.constant";
import { COMPONENT_NAME } from "./Preferences.vue";

export interface IPrefFeature {
  basic: string[];
  additional: string[];
  currency: {
    feature_base_currency: string;
    feature_decimal_format: string;
    feature_decimal_point: string;
    feature_discount_before_after_tax: string;
  };
  taxRate: {
    feature_purchase_tax_rate: string;
    feature_sales_tax_rate: string;
  };
}

export default Vue.extend({
  name: "PreferencesFeature",
  components: {
    SelectEmployee,
  },
  props: {
    firstLoad: Boolean,
    dataFirstLoad: {} as PropType<IPrefFeature>,
  },
  watch: {
    dataFirstLoad: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstLoad && newValue) {
          this.valPref.basic = newValue.basic;
          this.valPref.currency = newValue.currency;
          this.valPref.taxRate = newValue.taxRate;
          this.$emit("onChangeLoad", {
            name: COMPONENT_NAME.PREF_FEATURE,
            val: true,
          });
        }
      },
    },
  },
  computed: {
    ...mapState({
      preferenceField: (st: any) => st.preferenceStore.field,
    }),
  },
  data() {
    this.handleSearchCurrency = debounceProcess(this.handleSearchCurrency, 200);
    this.handleSearchSalesTaxRate = debounceProcess(
      this.handleSearchSalesTaxRate,
      200
    );
    this.handleSearchPurchaseTaxRate = debounceProcess(
      this.handleSearchPurchaseTaxRate,
      200
    );
    return {
      optBasicFeatures: OPTION_BASIC_FEATURE,
      optAdditionalFeatures: OPTION_ADDITIONAL_FEATURE,
      optDecimalFormat: [] as ResponseListMaster[],
      optBaseCurrency: [] as DataMasterCurrency[],
      optDecimalNumber: [] as ResponseListMaster[],
      optSalesTaxRate: [] as DataTax[],
      optSalesPurchaseRate: [] as DataTax[],
      optionDiscount: [] as ResponseListMaster[],
      adtIndeterminate: false,
      adtCheckAll: false,
      adtCheckedList: [],
      valPref: {
        basic: [] as string[],
        additional: [] as string[],
        currency: {
          feature_base_currency: "" as string,
          feature_decimal_format: "" as string,
          feature_decimal_point: "" as string,
          feature_discount_before_after_tax: "" as string,
        },
        taxRate: {
          feature_purchase_tax_rate: "" as string,
          feature_sales_tax_rate: "" as string,
        },
      } as IPrefFeature,
    };
  },
  methods: {
    ...mapMutations({
      mutatePreferenceField: "preferenceStore/mutatePreferenceField",
    }),
    onChangeFormat(value): void {
      this.valPref.currency.feature_decimal_format = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_FEATURE,
        val: this.valPref,
      });
    },
    onChangeBaseCurrency(value): void {
      this.valPref.currency.feature_base_currency = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_FEATURE,
        val: this.valPref,
      });
    },
    onChangeOption(value): void {
      this.valPref.currency.feature_decimal_point = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_FEATURE,
        val: this.valPref,
      });
    },
    onChangeBeforeAfterTax(value): void {
      this.valPref.currency.feature_discount_before_after_tax = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_FEATURE,
        val: this.valPref,
      });
    },
    onChangeSalesTaxRate(value): void {
      this.valPref.taxRate.feature_sales_tax_rate = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_FEATURE,
        val: this.valPref,
      });
    },
    onChangePurchaseTaxRate(value): void {
      this.valPref.taxRate.feature_purchase_tax_rate = value;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_FEATURE,
        val: this.valPref,
      });
    },
    onChangeBasic(checkedList) {
      this.valPref.basic = checkedList;
      this.$emit("onChange", {
        name: COMPONENT_NAME.PREF_FEATURE,
        val: this.valPref,
      });
    },
    onChangeAdditional(checkedList) {
      this.adtIndeterminate =
        !!checkedList.length &&
        checkedList.length < this.optAdditionalFeatures[0].child.length;
      this.adtCheckAll =
        checkedList.length === this.optAdditionalFeatures[0].child.length;
    },
    onCheckAllChange(e) {
      Object.assign(this, {
        adtCheckedList: e.target.checked
          ? this.optAdditionalFeatures[0].child.map(x => x.value)
          : [],
        adtIndeterminate: false,
        adtCheckAll: e.target.checked,
      });
    },
    handleSearchCurrency(value) {
      let paramsBaseCurrency: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (value) {
        paramsBaseCurrency.search = `currencyCode~*${value}*_OR_description~*${value}*`;
      }
      settingsServices
        .listOfMasterCurrency(paramsBaseCurrency, "")
        .then(responseBaseCurrency => {
          this.optBaseCurrency = responseBaseCurrency.data;
        });
    },
    handleSearchSalesTaxRate(value) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        search: "taxType~VAT_OUT",
      };
      if (value) {
        params.search = `code~*${value}*`;
      }
      settingsServices.listOfTax(params, "").then(response => {
        this.optSalesTaxRate = response.data;
      });
    },
    handleSearchPurchaseTaxRate(value) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        search: "taxType~VAT_IN",
      };
      if (value) {
        params.search = `code~*${value}*`;
      }
      settingsServices.listOfTax(params, "").then(response => {
        this.optSalesPurchaseRate = response.data;
      });
    },
    onChangeAssignee(e: Option<ListContactDataDTO>): void {
      const data: PreferenceField = {
        ...this.preferenceField,
        invoiceArAssignee: { label: e.label, value: e.value },
      };
      this.mutatePreferenceField(data);
    },
  },
  created() {
    const paramsDecimalFormat: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "DECIMAL_FORMAT",
    };
    const paramsDecimalOption: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "DECIMAL_POINT",
    };
    const paramsDiscountCalculation: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "DISCOUNT_CALCULATION",
    };
    masterServices
      .listMaster(paramsDecimalFormat)
      .then(responseDecimalFormat => {
        this.optDecimalFormat = responseDecimalFormat;
      });
    masterServices
      .listMaster(paramsDecimalOption)
      .then(responseDecimalOption => {
        this.optDecimalNumber = responseDecimalOption;
      });
    masterServices
      .listMaster(paramsDiscountCalculation)
      .then(responseDiscount => {
        this.optionDiscount = responseDiscount;
      });
    this.handleSearchCurrency("");
    this.handleSearchSalesTaxRate("");
    this.handleSearchPurchaseTaxRate("");
  },
});
